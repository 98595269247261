<template>
  <v-dialog
    :value="true"
    max-width="800"
    @click:outside="close"
    :persistent="generatingCoupons"
  >
    <v-card>
      <v-card-title>
        <span>
          {{ $t('create') }} {{ $t('coupon').toLowerCase() }}
        </span>
        <v-spacer />
        <v-btn
          large
          text
          color="error"
          :disabled="generatingCoupons"
          @click="close"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="currentStep">
          <v-stepper-header>
            <template v-for="(step, index) in steps">
              <v-stepper-step
                :key="step.key"
                :step="step.step"
              >
                {{ step.label }}
              </v-stepper-step>
              <v-divider
                v-if="index < (steps.length - 1)"
                :key="index"
              />
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <template v-for="(step) in steps">
              <v-stepper-content
                :key="step.key + '-content'"
                :step="step.step"
              >
                <v-container
                  v-if="step.step === 4"
                  fluid
                  class="pb-0"
                >
                  <v-row
                    justify="center"
                    align="center"
                  >
                    <v-col cols="12">
                      <v-progress-linear indeterminate />
                    </v-col>
                    <v-col
                      cols="12"
                      class="text-center text--secondary"
                    >
                      {{ $t('generatingCoupons') }}...
                    </v-col>
                  </v-row>
                </v-container>
                <v-container
                  v-else-if="step.step === 5"
                  fluid
                >
                  <v-row>
                    <v-col cols="12">
                      <div style="font-weight: 700; font-size: 16px;">
                        {{ $t('couponCreationSuccess', { createdCount: creationSuccessLength, totalCount: creationTotalLength }) }}
                      </div>
                      <div
                        v-if="creationFailureLength > 0"
                        style="font-weight: 500; font-size: 12px;"
                        class="error--text"
                      >
                        {{ $t('couponCreationFailure', { count: creationFailureLength }) }}
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container
                  v-else
                  fluid
                >
                  <v-row
                    v-if="Array.isArray(step.notes) && step.notes.length > 0"
                    dense
                  >
                    <v-col cols="12">
                      <v-alert
                        type="info"
                        class="mb-0"
                      >
                        <template v-for="(note, noteIndex) in step.notes">
                          <div :key="step.step + '-note-' + noteIndex">
                            {{ note }}
                          </div>
                        </template>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row v-if="Array.isArray(step.uiFields)">
                    <template v-for="field in step.uiFields">
                      <v-col
                        :key="field.key"
                        :cols="field.cols ? field.cols : '12'"
                      >
                        <DateAndTimeSelection
                          v-if="field.key === 'effectiveStartingAtTimestampInMillis' || field.key === 'effectiveEndingAtTimestampInMillis'"
                          :label="field.label"
                          :value="field.value"
                          filled
                          no-icon
                          hide-details
                          @setDateTime="(value) => updateValue({ field: field, value: value })"
                          :original-value="new Date().getTime()"
                        />
                        <div v-else-if="field.key === 'validHours'">
                          <div>
                            <v-alert
                              type="info"
                              class="mb-0"
                            >
                              {{ $t('couponHoursHint') }}
                            </v-alert>
                          </div>
                          <div
                            v-if="!Array.isArray(couponTemplate.validHours) || couponTemplate.validHours.length < 1"
                            class="text-center text--secondary pt-4"
                          >
                            {{ $t('noActiveHoursSet') }}
                          </div>
                          <template v-for="(hour, hourIndex) in couponTemplate.validHours">
                            <div
                              :key="`start-${hourIndex}`"
                              class="row align-center"
                            >
                              <div class="col-auto">
                                {{ $t('from') }}
                              </div>
                              <div class="col-auto">
                                <v-text-field
                                  :label="$t('from')"
                                  v-model="hour.startTime"
                                  v-mask="'##:##'"
                                  hide-details
                                  dense
                                  filled
                                  single-line
                                  @blur="parseHours(hour, hourIndex)"
                                />
                              </div>
                              <div class="col-auto">
                                {{ $t('to').toLowerCase() }}
                              </div>
                              <div class="col-auto">
                                <v-text-field
                                  :label="$t('to')"
                                  v-model="hour.endTime"
                                  v-mask="'##:##'"
                                  hide-details
                                  dense
                                  filled
                                  single-line
                                  @blur="parseHours(hour, hourIndex)"
                                  :suffix="hour.startTime > hour.endTime ? '(' + $t('nextDay') + ')' : ''"
                                />
                              </div>
                              <v-spacer />
                              <div class="col-auto">
                                <v-btn
                                  icon
                                  @click="() => { couponTemplate.validHours.splice(hourIndex, 1) }"
                                >
                                  <v-icon>
                                    mdi-trash-can
                                  </v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </template>
                          <div class="pt-4">
                            <v-btn
                              text
                              block
                              color="primary"
                              @click="addNewValidHour"
                            >
                              <v-icon left>
                                mdi-plus
                              </v-icon>
                              {{ $t('addNewActiveHours') }}
                            </v-btn>
                          </div>
                        </div>
                        <UiField
                          v-else
                          :value="field"
                          @change="updateValue"
                        />
                      </v-col>
                    </template>
                  </v-row>
                  <v-row
                    v-if="Array.isArray(step.actions) && false"
                    justify="space-between"
                  >
                    <template v-for="action in step.actions">
                      <v-col
                        :key="action.key"
                        :cols="action.cols ? action.cols : '12'"
                      >
                        <v-btn
                          @click="action.callback"
                        >
                          {{ action.label }}
                        </v-btn>
                      </v-col>
                    </template>
                  </v-row>
                </v-container>
              </v-stepper-content>
            </template>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions class="px-6 pb-4">
        <v-btn
          v-if="currentStep < 4"
          large
          :disabled="!canGoPrevious"
          color="primary"
          @click="previousStep"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
          {{ $t('previous') }}
        </v-btn>

        <v-spacer />
        <v-btn
          v-if="currentStep < 3"
          large
          :disabled="!canContinue"
          color="primary"
          @click="nextStep"
        >
          {{ $t('continue') }}
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-btn
          v-if="currentStep === 3"
          large
          :disabled="!canContinue || !canCreate"
          color="blue"
          class="white--text"
          @click="createCoupons"
        >
          {{ $t('create') }}
        </v-btn>
        <v-btn
          v-if="stepValue.key === 'complete'"
          large
          color="primary"
          @click="close"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UiField from "@/components/uiField"
import DateAndTimeSelection from "@/components/common/DateAndTimeSelection"
export default {
  name: "CouponCreateStepper",
  components: {
    DateAndTimeSelection,
    UiField
  },
  data() {
    return {
      currentStep: 1,
      valid: false,
      amountOfCouponsToCreate: 1,
      generatingCoupons: false,
      creationResult: null,
      couponTemplate: {
        active: true,
        code: null,
        label: null,
        description: null,
        maxRedemptions: null,
        timesRedeemed: 0,
        percentOff: null,
        effectiveStartingAtTimestampInMillis: null,
        effectiveEndingAtTimestampInMillis: null,
        validWeekdays: null,
        validHours: null,
        validItems: null,
        validItemCategories: null,
        item: null,
        customerName: null,
        customerPhone: null,
        customerEmail: null,
        validLocations: null
      }
    }
  },
  computed: {
    creationSuccessLength() {
      if(!Array.isArray(this.creationResult) || this.creationResult.length < 1) {
        return 0
      }

      return this.creationResult.filter(item => typeof item !== 'undefined' && item !== null).length
    },
    creationTotalLength() {
      if(!Array.isArray(this.creationResult) || this.creationResult.length < 1) {
        return this.amountOfCouponsToCreate
      }

      return this.creationResult.length
    },
    creationFailureLength() {
      if(!Array.isArray(this.creationResult) || this.creationResult.length < 1) {
        return this.amountOfCouponsToCreate
      }

      return this.creationResult.filter(item => item === null).length
    },
    stepValid() {
      if(this.currentStep >= 0) {
        const stepIndex = this.steps.findIndex(s => s && s.step === this.currentStep)
        if(stepIndex >= 0 && this.steps[stepIndex] && Array.isArray(this.steps[stepIndex].uiFields)) {
          const requiredFields = this.steps[stepIndex].uiFields.filter(field => field && field.required && field.key !== 'amountOfCouponsToCreate')
          return requiredFields.every(field => field && typeof this.couponTemplate[field.key] !== 'undefined' && this.couponTemplate[field.key] !== null && this.couponTemplate[field.key] !== '')
        }
      }
      return false
    },
    stepValue() {
      if(this.currentStep >= 0) {
        const stepValue = this.steps.find(s => s && s.step === this.currentStep)
        if(stepValue) {
          return stepValue
        }
      }
      return null
    },
    couponAmountOptions() {
      return Array.from(Array(1000), (_,i) => {
        const value = i + 1
        return {
          key: value,
          label: value
        }
      })
    },
    discountOptions() {
      return Array.from(Array(101), (_,i) => {
        return {
          key: i,
          label: i
        }
        // if(i % 5 === 0) {
        //   return {
        //     key: i,
        //     label: i
        //   }
        // }
      })
    },
    canGoPrevious() {
      if(this.currentStep > 1) {
        return true
      }
      return false
    },
    canCreate() {
      return true
    },
    canContinue() {
      return this.stepValid
    },
    steps() {
      const steps = []

      steps.push({
        label: this.$t('basicInfo'),
        key: 'basic',
        step: 1,
        notes: [this.$t('codesWillBeAutoGenerated')],
        valid: false,
        uiFields: [
          {
            label: this.$t('howManyCoupons'),
            key: 'amountOfCouponsToCreate',
            type: 'autocomplete',
            required: true,
            options: this.couponAmountOptions,
            noDataText: this.$t('minimumMaximum', { min: 1, max: 1000 }),
            rules: [ this.rules.required ],
            value: this.amountOfCouponsToCreate,
            cols: 6
          },
          {
            label: this.$t('discountPercent'),
            key: 'percentOff',
            type: 'autocomplete',
            required: true,
            options: this.discountOptions,
            noDataText: this.$t('minimumMaximum', { min: 0, max: 100 }),
            rules: [ this.rules.required ],
            value: this.couponTemplate.percentOff,
            cols: 6
          },
          {
            label: this.$t('label'),
            key: 'label',
            type: 'text',
            required: false,
            value: this.couponTemplate.label,
            hint: this.$t('couponLabelInfo'),
            persistentHint: true,
            hideDetails: false
          },
          {
            label: this.$t('description'),
            key: 'description',
            type: 'text',
            required: false,
            value: this.couponTemplate.description
          }
        ],
        actions: [
          {
            label: 'Previous',
            key: 'previous',
            disabled: true,
            cols: 'auto',
            callback: this.previousStep
          },
          {
            label: 'Continue',
            key: 'continue',
            disabled: false,
            cols: 'auto',
            callback: this.nextStep
          }
        ]
      })

      steps.push({
        label: this.$t('limitations'),
        key: 'limitations',
        step: 2,
        uiFields: [
          {
            label: this.$t('maxRedemptions'),
            key: 'maxRedemptions',
            type: 'number',
            required: true,
            rules: [ this.rules.required ],
            value: this.couponTemplate.maxRedemptions
          },
          {
            label: this.$t('couponItem'),
            key: 'item',
            type: 'select',
            required: false,
            options: this.couponItems,
            value: this.couponTemplate.item,
            noDataText: this.$t('noItemsOfTypeFound', { type: this.$t('coupon').toLowerCase() })
          },
          {
            label: this.$t('locations'),
            key: 'validLocations',
            type: 'select',
            required: false,
            options: this.locations,
            multiple: true,
            value: this.couponTemplate.validLocations,
            noDataText: this.$t('noLocationsFound')
          },
          {
            label: this.$t('customerName'),
            key: 'customerName',
            group: 'customer',
            type: 'text',
            required: false,
            value: this.couponTemplate.customerName,
            disabled: false
          },
          {
            label: this.$t('phone'),
            key: 'customerPhone',
            group: 'customer',
            type: 'text',
            required: false,
            cols: 6,
            value: this.couponTemplate.customerPhone,
            disabled: false
          },
          {
            label: this.$t('email'),
            key: 'customerEmail',
            group: 'customer',
            type: 'text',
            required: false,
            cols: 6,
            value: this.couponTemplate.customerEmail,
            disabled: false
          }
        ],
        actions: [
          {
            label: 'Previous',
            key: 'previous',
            disabled: true,
            callback: this.previousStep
          },
          {
            label: 'Continue',
            key: 'continue',
            disabled: false,
            callback: this.nextStep
          }
        ]
      })

      steps.push({
        label: this.$t('timeSettings'),
        key: 'time_settings',
        info: this.$t('couponActiveHint'),
        step: 3,
        uiFields: [
          {
            label: this.$t('activeFrom'),
            key: 'effectiveStartingAtTimestampInMillis',
            value: this.couponTemplate.effectiveStartingAtTimestampInMillis,
            type: 'dateTime',
            cols: 6,
          },
          {
            label: this.$t('activeTo'),
            key: 'effectiveEndingAtTimestampInMillis',
            value: this.couponTemplate.effectiveEndingAtTimestampInMillis,
            type: 'dateTime',
            cols: 6
          },
          {
            label: this.$t('activeWeekdays'),
            key: 'validWeekdays',
            type: 'select',
            multiple: true,
            hint: this.$t('activeWeekdaysHint'),
            persistentHint: true,
            options: this.weekdays,
            clearable: true,
            value: this.couponTemplate.validWeekdays
          },
          {
            label: this.$t('activeBetweenSpecificHours'),
            key: 'validHours',
            value: this.couponTemplate.validHours
          }
        ],
        actions: [
          {
            label: 'Previous',
            key: 'previous',
            disabled: false,
            callback: this.previousStep
          },
          {
            label: 'Create',
            key: 'create',
            disabled: false,
            callback: this.createCoupons
          }
        ]
      })

      steps.push({
        label: this.$t('create'),
        key: 'create',
        step: 4,
        uiFields: null,
        actions: null
      })

      steps.push({
        label: this.$t('complete'),
        key: 'complete',
        step: 5,
        uiFields: null,
        actions: [
          {
            label: this.$t('close'),
            key: 'close',
            disabled: false,
            callback: this.close
          }
        ]
      })

      return steps
    },
    rules() {
      return this.$store.getters.rules
    },
    couponItems() {
      const items = this.$store.state.items.filter(item => item && item.type === 'coupon')
      return this.$store.getters.itemsFormatForUiFields(items)
    },
    locations() {
      return this.$store.getters.locationsFormatForUiFields()
    },
    weekdays() {
      return this.$store.getters.weekdays.map(w => {
        return {
          key: w.value,
          label: w.text
        }
      })
    }
  },
  methods: {
    parseHours(hour, hourIndex) {
      if(hour && hourIndex >= 0) {
        for(const h in hour) {
          if(h && hour[h]) {
            const maxHour = 23
            const minHour = 0
            const maxMinutes = 59
            const minMinutes = 0

            try {
              const hourSplit = hour[h].split(':')
              let hours = Number(hourSplit[0])
              let minutes = Number(hourSplit[1])
              if(!isNaN(hours)) {
                if(hours < minHour) {
                  hours = minHour
                }
                if(hours > maxHour) {
                  hours = maxHour
                }
              }

              if(!isNaN(minutes)) {
                if(minutes < minMinutes) {
                  minutes = minMinutes
                }
                if(minutes > maxMinutes) {
                  minutes = maxMinutes
                }
              }

              this.couponTemplate.validHours[hourIndex][h] = [hours.toString().padStart(2, '0'), minutes.toString().padStart(2, '0')].join(':')
            } catch (e) {
              //
            }
          }
        }
      }
    },
    addNewValidHour() {
      try {
        if(!Array.isArray(this.couponTemplate.validHours)) {
          this.couponTemplate.validHours = []
        }
        this.couponTemplate.validHours.push({
          startTime: '00:00',
          endTime: '23:59'
        })
      } catch (e) {
        //
      }
    },
    createCoupons() {
      this.generatingCoupons = true
      this.nextStep() // To create step

      this.$store.dispatch('createMultipleCoupons', { quantity: this.amountOfCouponsToCreate, coupon: this.couponTemplate }).then(result => {
        this.creationResult = result
      }).catch(() => {
        this.creationResult = []
      }).finally(() => {
        this.nextStep() // To complete step
        this.generatingCoupons = false

        if(this.creationSuccessLength > 0) {
          const successPayload = {}
          successPayload.message = this.$t('couponCreationSuccess', { createdCount: this.creationSuccessLength, totalCount: this.creationTotalLength })
          if(this.creationFailureLength > 0) {
            successPayload.subMessage =  this.$t('couponCreationFailure', { count: this.creationFailureLength })
          }
          this.$store.commit('updateActionSuccess', successPayload)
          this.close()
        }
      })
    },
    updateValue({ field, value }) {
      try {
        if(field.key === 'amountOfCouponsToCreate') {
          this.amountOfCouponsToCreate = value
        } else if(field.key === 'maxRedemptions') {
          this.couponTemplate[field.key] = isNaN(Number(value)) ? 0 : Number(value)
        } else {
          this.couponTemplate[field.key] = value
        }
      } catch (e) {
        //
      }
    },
    nextStep() {
      this.currentStep = this.currentStep + 1
    },
    previousStep() {
      this.currentStep = this.currentStep - 1
    },
    close() {
      if(this.generatingCoupons === true) {
        return
      }
      this.$emit('closed')
    },
  }
}
</script>

<style scoped>

</style>
